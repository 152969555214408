import styled from "styled-components";

export const StyledWrapper = styled.section`
  display: flex;
  justify-content: center;
  margin-bottom: 50px !important;
  .box {
    display: flex;
    flex-direction: column;
    padding: 20px 0;
    margin: 0 auto;
    width: 95%;
    h1 {
      text-align: center;
      font-weight: 500;
      padding-bottom: 20px;
      color:black;
      margin-bottom:3rem;
    }
    .header {
      text-align: center;
      font-size: 50px;
      font-weight: bold;
      margin: 20px 0;
    }
    .body {
      text-align: center;
      text-transform: capitalize;
      font-size: 20px;
      margin: 30px 0;
    }
    .column {
      width:100%;
    }
    ul {
      margin: 40px auto;
      list-style: none;
      text-decoration: none;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .nav-item {
        margin: 0px auto;
        font-size: 20px;
        padding: 0px;
        color: #d0c9d6;
        text-transform: uppercase;
        cursor: pointer;
        .nav-link {
          color: #b4b4b4;
        }
        .nav-link.active {
          color: #333;
          border-top: none;
          border-left: none;
          border-right: none;
        }
      }
      .active {
        color: #333333 !important;
        border-bottom: 3px solid #6979f8 !important;
      }
    }
    .tab-content {
      display:grid;
      grid-gap:30px;
      grid-template-columns:repeat(auto-fill, minmax(300px, 1fr));
      .row {
        margin: 0px;
        display: none;
        .column {
          padding: 0px;
        }
      }
      .row.active {
        width:initial;
        display: block;
      }
    }
    .blog-cards {
      display:grid;
      grid-gap:30px;
      grid-template-columns:repeat(auto-fill, minmax(275px, 1fr));
      .column {
        margin:0 auto;
      }
    }
    @media (max-width: 768px) {
      .header {
        font-size: 35px;
      }
      ul {
        margin: 30px auto;
        .nav-item {
          font-size: 17px !important;
          padding: 0 15px !important;
          &:hover {
            border: none !important;
          }
          &:focus {
            border: none !important;
          }
        }
      }
      .tab-content {
        grid-gap:20px;
      }
      .blog-cards {
        grid-gap:50px;
        grid-template-columns:repeat(auto-fill, minmax(100%, 1fr));
      }
    }

    @media (max-width: 475px) {
      .tab-content {
        grid-template-columns:repeat(auto-fill, minmax(100%, 1fr));
      }
    }

    .advert {
      padding:30px 0px;
      position:relative;

      .details {
        position:absolute;
        color:white;
        top:150px;
        left:50px;

        div{
          margin-bottom:30px;
          span{
            font-size:2em;
            padding:10px 20px;
          }
        }

        .span {
          color:blue;
          border-radius:10px;
          padding:20px 50px;
          background:white;
        }
      }
    }
  }
  .search-box {
    height:45px;
    width:50%;
    min-width:400px;
    border:1px solid #847f7f;
  }
  @media (max-width:768px) {
    .box {
      width:100%;
    }
    .search-box {
      width:90%;
      min-width:1px;
    }
  }
`;
export const ThemeCard = styled.div`
  padding: 10px;
  width: 100%;
  height: 100%;
  display:flex;
  margin:0 auto;
  max-width:450px;
  border-radius: 5px;
  flex-direction:column;
  border: 0.5px solid #ddd;
  .theme-image {
    border-radius: 5px;
    height:200px;
    overflow:hidden;
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
    img {
      width: 100%;
      height: auto;
      border-radius: 5px;
      object-fit: cover;
    }
  }
  .theme-body {
    display: flex;
    position: relative;
    flex-direction: row;
    .theme-caption {
      padding: 15px 0;

      h3 {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 20px;
        font-weight: 550;
        padding-right: 0px !important;
        padding-bottom: 0px !important;
      }
      p {
        display: flex;
        justify-content: flex-start;
        font-size: 14px;
      }
    }
    .theme-button {
      color: #3329e0;
      padding: 8px 16px;
      border-radius: 3px;
      background: #ccc9ff; 
      align-items: center;
      justify-content: flex-start;
    }
  }
  .theme-footer {
    display: flex;
    flex-direction: row;
    margin-top:auto;
    align-items:center;
    .theme-badges {
      span {
        color:#5a55e4;
        margin: 0 auto;
        margin-right: 15px !important;
        padding: 3.5px 10px;
        font-size: 12px;
      }
      span:nth-child(1) {
        background: #ffe5d3;
        border-radius: 6px;
      }
      span:nth-child(2) {
        background: #ccf8fe;
        border-radius: 6px;
      }
      span:nth-child(3) {
        background: #e2fbd7;
        border-radius: 6px;
      }
    }
    a {
      margin-left: auto;
    }
    h3 {
      margin:0;
      font-size: 16px;
      color: #4339f2;
      color: #3329e0;
      padding: 8px 16px;
      border-radius: 3px;
      background: #ccc9ff;
    }
  }
`;
export const BlogCard = styled.div`
  padding: 10px;
  width: 100%;
  max-width:450px;
  margin:0 auto;
  .theme-image {
    width: 100%;
    border-radius: 5px;
    img {
      width: 100% !important;
      height: 100%;
      border-radius: 5px;
      object-fit: cover;
    }
  }
  .badge-cont {
    color:#F063B8;
    font-size:0.85em;
    font-weight:bold;
    margin:10px 0px;
  }
  .theme-body {
    display: flex;
    position: relative;
    flex-direction: row;
    padding: 15px 0px;
    .theme-caption {
      h3 {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 20px;
        font-weight:bold;
        padding-bottom: 0px !important;
      }
      p {
        display: flex;
        justify-content: flex-start;
        font-size: 15px;
      }
    }
  }
  .theme-footer {
    display: flex;
    flex-direction: column;
    h3 {
      font-size: 16px;
      color: #4339f2;
    }
  }
`;
