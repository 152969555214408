import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

import { Link, graphql } from 'gatsby';
import Helmet from 'react-helmet';
import { StyledWrapper, BlogCard } from '../components/Resources/Resources.styles';
import { Layout } from '../components/Layout/Layout';
import banner from '../components/images/blackfriday.png';
import Img from "gatsby-image/withIEPolyfill"

const Popup = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #0000009c;
  display: flex;
  z-index: 5;

  .popup-inner {
    height: 20rem;
    margin: auto;
    background: #000;
    display: flex;
    box-size: border-box;
    border-radius: 0.5rem;
    overflow: hidden;
  }

  .popup-content {
    width: 60%;
    color: #f9bc81;
    padding: 1.5rem 1rem 1.5rem 1.5rem;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  .popup-header {
    h3 {
      font-weight: bolder;
    }
  }

  .popup-footer {
    height: 2rem;
    display: flex;
    justify-content: space-between;

    input {
      background: transparent;
      border: 1px solid #f9bc81;
      padding: 0.4rem;
      color: #f9bc81;

      &::placeholder {
        color: #f9bc81;
      }
    }

    button {
      color: #000;
      font-size: 0.8rem;
      background: #f9bc81;
      border-radius: 0.35rem;
      padding: 0.4rem;
    }
  }

  .popup-image {
    width: 40%;

    img {
      height: 100%;
      object-fit: cover;
    }
  }
`;

export const PopUp = ({ show, setShow }) => {
  const wrapperRef = useRef(null);

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (show && wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShow(false);
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef, show]);

  return (
    <>
      {show && (
        <Popup className="popup">
          <div className="popup-inner" ref={wrapperRef}>
            <img src={banner} alt="banner" />
          </div>
        </Popup>
      )}
    </>
  );
};

const ResourcesPage = ({ data }) => {
  const posts = data.allMdx.nodes;

  return (
    <Layout>
      <Helmet>
        <title>Blog - Devwares</title>
        <meta name="title" content="Blog - Devwares" />
        <meta
          name="description"
          content="Get high quality articles and posts on various tools and resources for developers and designers"
        />
        <meta property="og:title" content="Blog - Devwares" />

        <meta
          property="og:description"
          content="Get high quality articles and posts on various tools and resources for developers and designers"
        />
        <meta property="twitter:title" content="Blog - Devwares" />
        <meta
          property="twitter:description"
          content="Get high quality articles and posts on various tools and resources for developers and designers"
        />
        <meta property="og:site_name" content="Devwares" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@devwares" />
        <meta name="twitter:creator" content="@devwares" />
      </Helmet>
      <StyledWrapper id="wrapper">
        {/* <PopUp show={show} setShow={setShow}/> */}
        <div className="box">
          <div className="box">
            <div className="header">Blog</div>
            <div className="search-box text-dark mx-auto d-flex align-items-center mb-5">
              <i className="fa fa-search mx-3"></i>
              <input
                type="search"
                className="border-0 h-100 w-100"
                style={{ outline: 'none' }}
                placeholder="Search"
              />
            </div>
          </div>
          <div className="blog-cards">
            {posts.map(post => {
              const title = post.frontmatter.title;
              return (
                <div className="row" key={post.fields.slug}>
                  <div className="column">
                    <BlogCard>
                      <Link to={'/blog' + post.fields.slug}>
                        <div className="theme-image">
                        {
                post.frontmatter?.imageExternal ?
                  <img  alt="postImage" src={post.frontmatter.imageExternal}  />
                  :
                  post.frontmatter?.image && post.frontmatter?.image?.childImageSharp?.fluid ?<Img
                    fluid={post.frontmatter.image.childImageSharp.fluid}
                    alt="postImage"
                  // className="aspect-video object-contain w-full h-[15rem] sm:h-[20rem] md:[20rem] lg:h-[70vh] xl:h-[80vh]"
                  /> : null
              }

                        </div>
                      </Link>
                      <div className="theme-body">
                        <div className="theme-caption">
                          <Link to={'/blog' + post.fields.slug}>
                            <h3>{title}</h3>
                          </Link>
                          <div className="badge-cont">
                            {post.frontmatter.tags !== null &&
                              post.frontmatter.tags.map(tag => (
                                <span key={tag} className="mx-2">
                                  {tag}
                                </span>
                              ))}
                          </div>
                          <p>{post.excerpt}</p>
                        </div>
                      </div>
                      <div className="theme-footer">
                        <Link
                          className="ml-auto d-flex align-items-center"
                          to={'/blog' + post.fields.slug}
                        >
                          <h3>
                            Read more
                            <i className="ml-2 fa fa-arrow-right"></i>
                          </h3>
                        </Link>
                      </div>
                    </BlogCard>
                  </div>
                </div>
              );
            })}
          </div>
          {/* <div>
            <img src={banner} alt="ad-banner" />
          </div> */}
        </div>
      </StyledWrapper>
    </Layout>
  );
};

export default ResourcesPage;

export const blogQuery = graphql`
  query MyQuery {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(filter: { frontmatter: { type: { eq: "blog" } } }) {
      nodes {
        frontmatter {
          metaTitle
          metaDescription
          date
          title
          image {
            childImageSharp {
              fluid(
                quality: 80,
                maxWidth: 1000
              ) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          imageExternal
          tags
        }
        excerpt
        fields {
          slug
        }
      }
      edges {
        node {
          id
        }
      }
    }
  }
`;
